import React from 'react';
import { graphql, Link } from 'gatsby';
import { Seo } from '../components/Seo';

const BlogPage = ({data}) => {
  const prismicData = [].concat(data.allPrismicMixedContentBlogPost.edges).reverse();
  const featuredPosts = prismicData.slice(0, 2)
  const stackedPosts = prismicData.slice(2, 6)
  const otherPosts = prismicData.slice(6, 6 + prismicData.length);
  return (
    <main>
      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6">
          <h1 className="text-4xl lg:text-6xl font-black mb-16">Featured <span className="text-sourci-pink">Posts</span></h1>

          <div className="grid lg:grid-cols-3 gap-x-16 gap-y-16">
            <div className="lg:col-span-2">
              <div className="mb-16">
                <Link to={`/blog/${featuredPosts[0].node.uid}`}>
                  <div className="relative w-full h-0 pb-[60%] rounded-3xl overflow-hidden mb-2.5">
                    <img src={featuredPosts[0].node.data.cover_image.url}
                      className="max-w-none h-full w-auto absolute top-[-9999px] left-[-9999px] right-[-9999px] bottom-[-9999px] m-auto group-hover:scale-105 transition-transform duration-300"
                      alt={featuredPosts[0].node.data.cover_image.alt || featuredPosts[0].node.data.title[0].text} />
                  </div>
                  <p className="text-slate-500 mb-2">Posted on {featuredPosts[0].node.data.post_date}</p>
                  <p className="font-black text-black text-3xl lg:text-4xl mb-4">{featuredPosts[0].node.data.title[0].text}</p>
                  <p className="text-lg text-slate-500">{featuredPosts[0].node.data.description[0].text}</p>
                </Link>

              </div>
              <div>
                <Link to={`/blog/${featuredPosts[1].node.uid}`}
                  className="grid lg:grid-cols-2 gap-x-8">
                  <div className="relative w-full h-0 pb-[60%] rounded-3xl overflow-hidden mb-2.5">
                    <img src={featuredPosts[1].node.data.cover_image.url}
                      className="max-w-none h-full w-auto absolute top-[-9999px] left-[-9999px] right-[-9999px] bottom-[-9999px] m-auto group-hover:scale-105 transition-transform duration-300"
                      alt={featuredPosts[1].node.data.cover_image.alt || featuredPosts[1].node.data.title[0].text} />
                  </div>
                  <div>
                    <p className="text-slate-500 mb-2 text-sm">Posted on {featuredPosts[1].node.data.post_date}</p>
                    <p className="font-black text-black text-xl lg:text-2xl mb-4">{featuredPosts[1].node.data.title[0].text}</p>
                    <p className="text-slate-500">{featuredPosts[1].node.data.description[0].text}</p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-1 gap-y-8 gap-x-12">
              {stackedPosts.map((i,k) => (
                (k === 0 
                  ? <Link key={k} to={`/blog/${i.node.uid}`}
                      className="mb-8">
                    <div className="relative w-full h-0 pb-[60%] rounded-3xl overflow-hidden mb-2.5">
                      <img src={i.node.data.cover_image.url}
                        className="max-w-none h-full w-auto absolute top-[-9999px] left-[-9999px] right-[-9999px] bottom-[-9999px] m-auto group-hover:scale-105 transition-transform duration-300"
                        alt={i.node.data.cover_image.alt || i.node.data.title[0].text} />
                    </div>
                    <p className="text-sm text-slate-500 mb-2">Posted on {i.node.data.post_date}</p>
                    <p className="font-black text-black text-xl lg:text-2xl mb-4">{i.node.data.title[0].text}</p>
                    <p className="text-slate-500">{i.node.data.description[0].text}</p>
                  </Link>
                
                  : <Link key={k} to={`/blog/${i.node.uid}`}
                  className="grid lg:grid-cols-2 gap-x-4 mb-8 lg:mb-0">
                  <div className="relative w-full h-0 pb-[60%] lg:pb-[100%] rounded-3xl overflow-hidden mb-2.5">
                    <img src={i.node.data.cover_image.url}
                      className="max-w-none h-full w-auto absolute top-[-9999px] left-[-9999px] right-[-9999px] bottom-[-9999px] m-auto group-hover:scale-105 transition-transform duration-300"
                      alt={i.node.data.cover_image.alt || i.node.data.title[0].text} />
                  </div>
                  <div>
                    <p className="text-slate-500 mb-2 text-sm">Posted on {i.node.data.post_date}</p>
                    <p className="font-black text-black mb-4 text-xl lg:text-base">{i.node.data.title[0].text}</p>
                    <p className="text-slate-500 lg:hidden">{i.node.data.description[0].text}</p>
                  </div>
                </Link>
                )
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-24">
        <div className="xl:container mx-auto px-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-12">
            {otherPosts.map((i,k) => (
              <Link to={`/blog/${i.node.uid}`}
                className="mb-8"
                key={k}>
                <div className="relative w-full h-0 pb-[60%] rounded-3xl overflow-hidden mb-2.5">
                  <img src={i.node.data.cover_image.url}
                    className="max-w-none h-full w-auto absolute top-[-9999px] left-[-9999px] right-[-9999px] bottom-[-9999px] m-auto group-hover:scale-105 transition-transform duration-300"
                    alt={i.node.data.cover_image.alt || i.node.data.title[0].text} />
                </div>
                <p className="text-sm text-slate-500 mb-2">Posted on {i.node.data.post_date}</p>
                <p className="font-black text-black text-xl lg:text-2xl mb-4">{i.node.data.title[0].text}</p>
                <p className="text-slate-500">{i.node.data.description[0].text}</p>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentBlogPost(sort: {fields: data___post_date}) {
      edges {
        node {
          data {
            post_date(formatString: "MMM DD, YYYY")
            cover_image {
              url
              alt
            }
            title {
              text
            }
            description {
              text
            }
          }
          uid
          first_publication_date
        }
      }
    }
  }
`

export default BlogPage;

export function Head({data}) {
  return (
    <Seo title="Blog | Sourci"
      canonical="/blog" />
  )
}